@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.App {
  font-family: 'Montserrat', sans-serif !important
}

#heroSection {
  height: 100vh;
  /* background-color: #6B64FF; */
}

header {
  padding: 1rem 5rem;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: sticky;
  top: 0px;
  z-index: 9999;
}

.primaryBtn {
  background-color: #6B64FF;
  color: white;
  font-size: 16px;
  font-weight: 600;
  border: none;
  padding-left: 20px;
  padding-right: 20px;
  height: 40px;
  border-radius: 10px;
  font-family: 'Montserrat', sans-serif;
  cursor: pointer;
}

.secondaryBtn {
  background-color: #6B64FF;
  color: white;
  font-size: 16px;
  font-weight: 600;
  border: none;
  padding-left: 20px;
  padding-right: 20px;
  height: 40px;
  border-radius: 10px;
  font-family: 'Montserrat', sans-serif;
  cursor: pointer;
}

#heroBanner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  margin-top: -72px;
}

#heroBanner h1{
  color: #6B64FF;
  font-size: 88px;
  font-weight: 800;    
  margin: 0px;
}

#heroBanner p{
  color: #322E87;
  font-size: 18px;
  font-weight: 500;    
  margin: 0px;
  margin-bottom: 2rem;
  text-align: center;
}

#featuresSection {
  padding: 1rem 4rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.card {
  border-radius: 24px;
  background-color: #322E87;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
  padding: 1rem;
  width: 22%;
  color: white;
}

p.cardHeader {
  margin: 0px;
  font-size: 16px;
  font-weight: 600;
}

p.cardText {
  margin: 0px;
  font-size: 16px;
  font-weight: 400;
}

#featuresSection svg {
  height: 40px;
  color: white;
  width: auto;
  padding-right: 1rem;
}

.navLanguageToggle {
  display: flex;
  flex-direction: row;
  margin-left: 2rem;
  margin-right: 1rem;
}

.navLanguageToggle p {
  margin-bottom: 0px;
  cursor: pointer;
  margin-top: 0px;
  color: #322E87;
}

.navLanguageToggle p.active {
  font-weight: 600;
  color: #6B64FF;
}

footer {
  padding: 1rem 5rem;
  padding-top: 5rem;
}

footer p {
  font-size: 14px;
  margin: 0px;
  color: #322E87;
}

@media only screen and (min-width: 768px) {
  .hideOnDesktop {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .hideOnMobile {
    display: none;
  }

  header {
    padding: 1rem 1rem;
  }

  #heroBanner {
    padding: 1rem 1rem;
    padding-bottom: 2rem;
  }

  #heroBanner h1{
    font-size: 40px;
  }

  #featuresSection {
    padding-left: 1rem;
  }

  .card {
    width: 600px;
  }

  #featuresSection svg {
    padding-left: 0px;
    padding-right: 24px;
  }

  footer {
    padding: 1rem 1rem;
    padding-top: 5rem;
    text-align: center;
  }
}